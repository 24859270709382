import React, {useEffect, useState} from 'react';
import {functions, logos} from '../../utils/appRessource';
import './list.scss';
import {useSelector} from 'react-redux';
import searchService from '../../services/search.service';
import {icons, components} from '../../utils/appRessource';

let page = 0;
let isFetching = false;
let lastSearchData = [];
let noMorePage = false;

function List(props) {
    const {t} = functions.translate();
    const [searchData, setSearchData] = useState([])
    const [searchErrorData, setSearchErrorData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const dateFrom = useSelector(state => state.searchList.dateFrom);
    const dateTo = useSelector(state => state.searchList.dateTo);
    const site = useSelector(state => state.searchList.site);
    const [openDialog, setOpenDialog] = useState(false);
    const [orderIdSelected, setOrderIdSelected] = useState(null);
    const [orderSiteSelected, setOrderSiteSelected] = useState(null);
    const isHome = props.isHome;

    async function handleFetchList() {
        if (isFetching) {
            return;
        }
        isFetching = true;
        setLoading(true);
        if (page === 0) {
            lastSearchData = [];
            setSearchData([]);
        }
        try {
            console.log(dateFrom, dateTo, page, site);
            const data = await searchService.search(dateFrom, dateTo, page, site);
            if (data) {
                if (data.error) {
                    setSearchErrorData(data.errorMessage);
                } else {
                    if (page === 0) {
                        noMorePage = false;
                        lastSearchData = data.orders || [];
                        setSearchData(lastSearchData);
                    } else {
                        if (data.orders.length === 0) {
                            noMorePage = true;
                        } else {
                            const newData = lastSearchData.concat(data.orders)
                            lastSearchData = newData;
                            setSearchData(newData);
                            noMorePage = data.orders.length < 20;
                        }
                    }
                }
            }
        } catch (err) {
            console.log(err);
            setSearchErrorData(err);
        }
        finally {
            setLoading(false);
            isFetching = false;
        }
    }

    const handleOpenDialog = (orderId, orderSite) => {
        setOrderIdSelected(orderId);
        setOrderSiteSelected(orderSite);
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOrderIdSelected(null);
        setOrderSiteSelected(null);
        setOpenDialog(false);
    }

    const getLogoOrder = (order) => {
        const site = order.indexOf('_');
        const brand = order.substring(0, site !== -1 ? site : order.length).toLowerCase();
        let logo = "";
        if (brand === 'etam') {
            logo = <logos.etam/>
        } else if (brand === '123') {
            logo = <logos.maison123/>
        } else if (brand === 'undiz') {
            logo = <logos.undiz/>
        } else if (brand === 'livy') {
            logo = <logos.livy/>
        }

        return logo;
    }

    useEffect(() => {
        page = 0;
        handleFetchList();
        // eslint-disable-next-line
    }, [dateFrom, site]);

    useEffect(() => {
        const handleScroll = () => {
            if (!noMorePage && !isFetching && lastSearchData.length > 0 && window.scrollY > (document.body.scrollHeight - window.innerHeight - 200)) {
                ++page;
                handleFetchList();
            }
        };

        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className="list__container p-4">
            <h2 className="xl:text-xl lg:text-base font-bold mb-5">
                {t('header.list')}
            </h2>
            <div className="list__header not-prose relative rounded-xl overflow-hidden dark:bg-slate-80 ">
                {searchErrorData && !searchData
                    ?
                    <div className='error'>{searchErrorData}</div>
                    :
                    <div className="relative rounded-xl">
                        <div className="shadow-sm overflow-x-scroll mt-4 mb-8">
                            <table className="border-collapse table-auto sm:w-screen lg:w-full xl:text-sm lg:text-xs">
                                <thead>
                                <tr>
                                    {
                                        isHome &&
                                        <th className="border-b font-medium p-4 pl-4 pt-0 pb-3 text-left">{t('order.brand')}</th>
                                    }
                                    <th className="border-b font-medium p-4 pl-4 pt-0 pb-3 text-left">{t('order.numberOrder')}</th>
                                    <th className="border-b font-medium p-4 pt-0 pb-3 text-left">{t('order.dateOrder')}</th>
                                    <th className="border-b font-medium p-4 pr-8 pt-0 pb-3 text-left">{t('order.emailOrder')}</th>
                                    <th className="border-b font-medium p-4 pr-8 pt-0 pb-3 text-left">{t('order.statusOrder')}</th>
                                    <th className="border-b font-medium p-4 pt-0 pb-3 text-left">{t('order.paiementStatus')}</th>
                                    <th className="border-b font-medium p-4 pr-8 pt-0 pb-3 text-left">{t('order.paymentMethod')}</th>
                                    <th className="border-b font-medium p-4 pr-8 pt-0 pb-3 text-left">{t('order.shippingStatus')}</th>
                                    <th className="border-b font-medium p-4 pr-8 pt-0 pb-3 text-left">{t('order.site')}</th>
                                    <th className="border-b font-medium p-4 pr-8 pt-0 pb-3 text-left">{t('order.actions')}</th>
                                </tr>
                                </thead>
                                <tbody className="bg-white dark:bg-slate-800">
                                {
                                    searchData.length > 0
                                        ?
                                        searchData.map((order, i) => {
                                            const date = new Date(order.orderDate);
                                            const formatDate = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
                                            const logo = getLogoOrder(order.site);
                                            return (
                                                <tr key={i} className='list__order'>
                                                    {
                                                        isHome &&
                                                        <td className="border-b border-slate-100 p-4 text-slate-500">
                                                            <div
                                                                className='list__logoOrder rounded-full p-4'>{logo}</div>
                                                        </td>
                                                    }
                                                    <td className="border-b border-slate-100 p-4 text-slate-500">{order.orderNo}</td>
                                                    <td className="border-b border-slate-100 p-4 text-slate-500">{formatDate}</td>
                                                    <td className="border-b border-slate-100 p-4 text-slate-500 lowercase">{order.customerEmail}</td>
                                                    <td className="border-b border-slate-100 p-4 text-slate-500">{order.orderStatus}</td>
                                                    <td className="border-b border-slate-100 p-4 text-slate-500">{order.paymentStatus}</td>
                                                    <td className="border-b border-slate-100 p-4 text-slate-500">{order.paymentMethod}</td>
                                                    <td className="border-b border-slate-100 p-4 text-slate-500">{order.shippingStatus}</td>
                                                    <td className="border-b border-slate-100 p-4 text-slate-500">{order.site}</td>
                                                    <td className="border-b border-slate-100 p-4 text-slate-500 cursor-pointer"
                                                        onClick={() => handleOpenDialog(order.orderNo, order.site)}>
                                                        <icons.eyeOpen/>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        !isLoading && <tr>
                                            <td colSpan={isHome ? 10 : 9}
                                                className="text-center border-b border-slate-100 p-4 text-slate-500">{t('order.noresult')}</td>
                                        </tr>
                                }
                                {isLoading && <tr>
                                    <td colSpan={isHome ? 9 : 8}
                                        className="text-center border-b border-slate-100 p-4 text-slate-500">
                                        <components.loader color={"white"}/>
                                    </td>
                                </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
            {
                searchData.length > 0 &&
                <components.dialogDetailOrder open={openDialog} onClose={handleCloseDialog} orderId={orderIdSelected}
                                              orderSite={orderSiteSelected}/>
            }
        </div>
    );
}

export default List;
